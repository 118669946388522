















































































































































import RefreshChats from '@/components/RefreshChats.vue'
import Statistics from '@/includes/logic/Statistics'
import Api from '../includes/logic/Api'
import IfChatsEmpty from '@/components/IfChatsEmpty.vue'
import ChatCard from '@/components/vu-components/ChatCard.vue'
import { BaseChatInfo, BaseChatInfoNetworkData } from '@/includes/types/Chat/types'
import { getNetworkTitle, getPrivacyAlias } from "@/includes/logic/Networks/utils";
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";

import AddEntityCard from 'piramis-base-components/src/components/AddEntityCard.vue'
import { fromToTicks } from "piramis-base-components/src/shared/utils/fromToTicks";
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'

import moment from 'moment'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  methods: {
    getPrivacyAlias,
    getNetworkTitle
  },
  components: {
    RefreshChats,
    IfChatsEmpty,
    ChatCard,
    AddEntityCard,
    Icon
  }
})
export default class Chats extends Mixins(NetworksHelperMixin) {

  chatsStat:any = []

  chatsWithNetworks: Array<{ network: BaseChatInfoNetworkData, chats: Array<BaseChatInfo> }> = []
  chatsWithoutNetworks: Array<BaseChatInfo> = []

  statisticsLoaded = false

  chatsLoading = false

  get filterChatsByMembers(): Array<BaseChatInfo> {
    return this.$store.getters.sortedVisibleChatsInfos
  }

  updateChatInfo(data: { id: number, diagnostic: BaseChatInfo['diagnostic'] }) {
    this.$store.commit('updateChatInfo', {
      chatId: data.id,
      config: { diagnostic: data.diagnostic }
    })
  }

  prepareChatsStatistics(data:any):any {
    const resObject:any = {}

    const {
      dataTicks,
      timeTicks
    } = fromToTicks(moment().add(-7, 'days').startOf('day').format(), moment().format(), { format: 'YYYY-MM-DD HH:mm:ss' })

    data.map((chatStatistics:any) => {
      const usersSeries = {
        name: this.$t('chat_card_statistics_users'),
        data: [ ...dataTicks ],
      }
      const msgsSeries = {
        name: this.$t('chat_card_statistics_msgs'),
        data: [ ...dataTicks ],
      }

      chatStatistics.activity.map((item:any) => {
        if (timeTicks.includes(item.time)) {
          const index = timeTicks.indexOf(item.time)
          if (item.users) {
            usersSeries.data[index] = item.users
          }
          if (item.msgs) {
            msgsSeries.data[index] = item.msgs
          }
        }
      })

      resObject[chatStatistics.chat_id] = {
        leave: chatStatistics.leave,
        new: chatStatistics.new,
        total: chatStatistics.total,
        series: [ usersSeries, msgsSeries ],
      }
    })

    return resObject
  }

  gotoAddGroupLink() {
    window.open('https://t.me/chatkeeperbot?startgroup&admin=restrict_members+delete_messages+invite_users+pin_messages+manage_topics', '_blank')
  }

  destroyed() {
    this.$baseTemplate.saveButton.show()
  }

  getNetworksInfo() {
    const chatsWithoutNetworks: Array<BaseChatInfo> = []
    const chatsWithNetworks: Array<{ network: BaseChatInfoNetworkData, chats: Array<BaseChatInfo> }> = []

    ;((this.filterChatsByMembers as Array<BaseChatInfo>) ?? []).forEach(chat => {
      if (!chat.networks.length) {
        chatsWithoutNetworks.push(chat)
      } else {
        chat.networks.forEach(n => {
          const existedGn = chatsWithNetworks.find(gn => gn.network.id === n.id)

          if (!existedGn) {
            chatsWithNetworks.push({
              network: n,
              chats: [ chat ]
            })
          } else {
            existedGn.chats.push(chat)
          }
        })
      }
    })

    this.chatsWithNetworks = chatsWithNetworks.sort((a, b) => b.chats.length - a.chats.length)
    this.chatsWithoutNetworks = chatsWithoutNetworks
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()

    this.getNetworksInfo()

    const chatsInfoString = localStorage && localStorage.getItem('chats_info_stat')
    let chat_info_stat:any;

    if (chatsInfoString) {
      chat_info_stat = JSON.parse(chatsInfoString)
    }

    const ONE_HOUR = 1000 * 3600

    if (chat_info_stat && ((Date.now() - chat_info_stat.time) < ONE_HOUR)) {
      this.chatsStat = chat_info_stat.data
      this.statisticsLoaded = true
    } else {
      const statistics = new Statistics(Api.getMethodUrl('tg', 'getchatsinfostat'))

      statistics.getChatsStatistics()
        .then(res => {
          this.chatsStat = this.prepareChatsStatistics(res.data.chats_stat)

          if (localStorage) {
            localStorage.setItem('chats_info_stat', JSON.stringify({
              data: this.chatsStat,
              time: Date.now(),
            }))
          }

          this.statisticsLoaded = true
        })
    }
  }
}
